import "./App.css";

export default function App() {
  return (
    <div className="App">
      <div class="container">
        <header>
          <h1 class="title">Joshua Lomond</h1>
          <a href="https://www.linkedin.com/in/joshua-lomond-937126162/">
            LinkedIn
          </a>
          <a href="mailto:josh.lomond@dal.ca">josh.lomond@dal.ca</a>
          <p>(###) ###-####</p>
        </header>
        <section>
          <h2 class="title">Summary</h2>
          <p>Computer Science student with software-development experience.</p>
          <p>
            Aiming to utilise technical and interpersonal skills while
            supporting the creation of innovative solutions for real-world
            problems.
          </p>
        </section>
        <section>
          <h2 class="title">Experience</h2>
          <div class="indent">
            <h3>Software-Developer Student</h3>
            <p>Beford Institute of Oceanography, Fisheries & Oceans Canada</p>
            <p>January 2024 - May 2024</p>
          </div>
          <div class="indent">
            <h3>Cast-Member</h3>
            <p>Cineplex Entertainment, Halifax</p>
            <p>June 2023 - December 2023</p>
          </div>
        </section>
        <section>
          <h2 class="title">Education</h2>
          <div class="indent">
            <h3>Bachelor's Computer Science (B.Cs)</h3>
            <p>Dalhousie University</p>
            <p>September 2021 - Present</p>
          </div>
        </section>
        <section>
          <h2 class="title">Skills</h2>
          <ul>
            <li>Java, TypeScript (JavaScript), HTML, CSS</li>
            <li>Angular, React, ReactNative, Unity</li>
            <li>MySQL, PostgreSQL, MongoDB</li>
          </ul>
        </section>
      </div>
    </div>
  );
}
